<template>
        <b-card>
		<b-img
			:src="require('@/assets/images/logo/DOMICIALIZ-LOGO.png')"
			center
		/><br>
		<b-carousel
			id="carousel-example-generic"
			controls
			indicators
			background="#ababab"
			style="text-shadow: 1px 1px 2px #333;"
		>
			<b-carousel-slide :img-src="require('@/assets/images/illustration/domicaliz.png')" />
		</b-carousel><br>
			<b-card-text>
				<div class="text-center" ><div class="badge badge-primary" >Bienvenue chez Domicializ.</div></div><br>
				<div class="text-center" ><strong>Domiciliez-vous à la Réunion en 3 clics,
à partir de 14,99€HT</strong></div>
			</b-card-text>
			<b-button
			block
			Primary
			@click="openurl('https://domicializ.fr')"
			>
			Visiter le site
			</b-button>
        </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
	
export default {
	components: {
		BCard,
		BCardText,
		BButton,
		BImg,
		BCarousel,
		BCarouselSlide,
	},
}
</script>
